import styled from "styled-components";
import BaseWrapper from "../common/BaseArea";
import CSSGlobalVars from "../../../utils/CSSGlobalVars";
import logo from '../../../assets/images/logo.png'
import ig from '../../../assets/images/ig.png'
import { useState } from "react";
import { addUserAddress } from "../../../utils/WebService";


const Wrapper = styled(BaseWrapper)`

    height: 300px;
    width: 100%;
    padding-left: 0%;

    background-color: #3250ED;   

    display: grid;

    grid-template-columns: 20% 40% 40% ;



    position: relative;


    @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
        grid-template-columns: 100%;

        height: 400px;

    }

`

const WithLoveLogo = styled.img`

    width: 150px;
    justify-self:center;
    align-self: center;

    @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
        display: none;
    }

`


const FormBox = styled.div`

    width: 100%;
    max-width: 800px;
    height: 80%;
    padding: 30px;
    

    background-color: #3250ED;    
    color: white;

    font-family: Tiposka;

    display: flex;

    flex-direction: column;
    align-items: start;
    justify-content: space-around;
    text-align: left;


    @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
        width: 100%;
        height: 100%;
        padding: 0px;

        margin-top: 30px;
    } 
    
    .header{
        font-size: 22px;

        @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
            width: 80%;
            margin-left: 10%;
        } 
       
    }

    .content{
        font-size:12px;
        @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
            width: 80%;
            margin-left: 10%;
        } 
    }

    .form-items{
        width: 100%;
        margin-top: 20px;
        display: grid;
        grid-template-rows: 50% 50%; 

        justify-items: left;
        align-items: center;
        text-align: left;

        input{
            width: 80%;
            height: 45px;
            padding-left: 10px;
            text-transform: uppercase;
            border: 0px;

            border-radius: 50px;

        }

        .submit{
            margin-top: 10px;
            background-color: white;
            width: 130px;   
            height: 45px;
            display: grid;
            justify-items: center;
            align-items: center;
            cursor: pointer;

            border-radius: 50px;

            color: #3250ED; 

            opacity: ${(props)=>props.sending ? 0.5 : 1};
        }

        @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
            margin-left: 10%;
        } 
    }


`

const SocialWrapper = styled.div`


    margin-left: 10%;
    width: 90%;
    display: grid;
    align-items: center;

    padding: 30px;
    

    height: 100px;

    justify-content: left;
    text-align: left;

    font-family: IBM Plex Mono;
    font-size:12px;

    

    

    p{
        color: white;
    }

    a{
        color: inherit; /* blue colors for links too */
        text-decoration: underline;
    }

    .social-channel{
       display : grid;
       grid-template-columns: 30px auto;

       justify-content: left;
       align-items: center;

       .social-image{
        width: 20px;
       }
    }

    @media only screen and (max-width: ${CSSGlobalVars.phoneCap}px) {
        margin-top: 20px;
        margin-left: 0%;

    }

`


const GetInvolved = ()=>{


    const [email , setEmail] = useState('');
    const [sending , setSending] = useState(false);


    const onSubmit = async()=>{

        if(sending)return;

        setSending(true);
        await addUserAddress(email)
        setEmail('');
        setSending(false);

        
    }

    const onUpdateEmail = (evt)=>{
        setEmail(evt.target.value);
    }

    return(
        <Wrapper>
            <WithLoveLogo src={logo}></WithLoveLogo>
            <FormBox sending={sending}>
                <p className="header">Want to get involved?</p>
                <p className="content">Sign up to have the chance to receive a postcard written by AI.</p>
                <p className="content">Full Adress*</p>
                <div className="form-items">
                    <input 
                    value={email}
                    onChange={onUpdateEmail} type="text"/>
                    <p className="submit" onClick={onSubmit}>Submit</p>
                </div>
            </FormBox>
            <SocialWrapper>
                <p>Follow the project updates</p>
                <div className="social-channel">
                    <img className="social-image" src={ig} alt="instagram logo"></img>
                    <p><a href="https://www.instagram.com/withlovefrom.a.i/" rel="noreferrer" target="_blank">@withlovefrom.a.i</a></p>
                </div>
                <p><a href="https://www.pedrogarlaschi.com/contact-flatiron" rel="noreferrer" target="_blank">About maker</a></p>
            </SocialWrapper>
        </Wrapper>
    )
}

export default GetInvolved;


//