import styled from "styled-components";

const Wrapper = styled.div`

    width: 100%;
    height: 100px;

    
    background-color: #3250ED;   

    display: grid;
    align-items: center;
    justify-items: left;

    p{  
        font-family: Tiposka;
        font-size: 10px;
        margin-left: 10%;
        color: white;

        a{
            color: inherit; /* blue colors for links too */
            text-decoration: underline;
        }
    }

`




const Footer = ()=>{

    return (
        <Wrapper>
            <p>© 2025 <a href="http://www.pedrogarlaschi.com" rel="noreferrer" target="_blank">Pedro Garlaschi</a> - All Rights Reserved. </p>
        </Wrapper>
    )

}


export default Footer;
