import axios from 'axios';

export const IS_LOCAL = false;

export const baseURL = ()=>{

    if(IS_LOCAL)
        return 'http://127.0.0.1:5001/with-love-from-ai/us-central1/app'
    else
        return 'https://us-central1-with-love-from-ai.cloudfunctions.net/app'
}

export const createPostcard = async (image)=>{

    return new Promise(async(resolve , reject)=>{

        try{
            const url = `${baseURL()}/createPostcard`;
            const query = await axios.post(url, { image: image });
            resolve(query.data);
        }
        catch(error){
            reject(error);
        }
    })
}

export const deletePostcard = async (id)=>{

    return new Promise(async(resolve , reject)=>{

        try{
            const url = `${baseURL()}/deletePostcard`;
            const query = await axios.post(url, { id: id });
            resolve(query.data);
        }
        catch(error){
            reject(error);
        }
    })
}

export const approvePostcard = async (id)=>{

    return new Promise(async(resolve , reject)=>{

        try{
            const url = `${baseURL()}/approvePostcard`;
            const query = await axios.post(url, { id: id });
            resolve(query.data);
        }
        catch(error){
            reject(error);
        }
    })
}

export const addUserAddress = async (address)=>{

    return new Promise(async(resolve , reject)=>{

        try{
            const url = `${baseURL()}/addUserAddress`;
            const query = await axios.post(url, { address: address });
            resolve(query.data);
        }
        catch(error){
            reject(error);
        }
    })
}









export const getSentPostcards = async ()=>{

    return new Promise(async(resolve , reject)=>{

        try{
            const url = `${baseURL()}/getSentPostcards`;
            const query = await axios.get(url);
            resolve(query.data);
        }
        catch(error){
            reject(error);
        }
    })
}

export const getPendingPostcards = async ()=>{

    return new Promise(async(resolve , reject)=>{

        try{
            const url = `${baseURL()}/getPendingPostcards`;
            const query = await axios.get(url);
            resolve(query.data);
        }
        catch(error){
            reject(error);
        }
    })
}


export const getPostcards = async ()=>{

    return new Promise(async(resolve , reject)=>{

        try{
            const url = `${baseURL()}/getPostcards`;
            const query = await axios.get(url);
            resolve(query.data);
        }
        catch(error){
            reject(error);
        }
    })
}



